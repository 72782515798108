import { createEffect } from 'solid-js';
import { useStyling } from '~/global-contexts/CompanyStyling';

interface TitleProps {
  title: string;
}

export const useTitle = (props: TitleProps) => {
  const { companyStyling } = useStyling();
  createEffect(() => {
    const titleTemplate = companyStyling?.()?.metadataTitleTemplate;
    if (!titleTemplate) {
      document.title = props.title;
    } else {
      document.title = titleTemplate.replace('%s', props.title);
    }
  });
};
